import Amplify from "aws-amplify";
import "./global.css";

Amplify.configure({
  API: {
    endpoints: [
      {
        name: "user",
        endpoint: process.env.GATSBY_API_ENDPOINT,
      },
    ],
  },
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: "eu-west-1",
    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    identityPoolRegion: "eu-west-1",
    // OPTIONAL - Amazon Cognito User Pool ID
    //userPoolId: 'eu-west-1_cJaFU5HyA', // MFA on
    //userPoolId: 'eu-west-1_eS7b5iSW3', // MFA optional
    userPoolId: process.env.GATSBY_USERS_ID,
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    //userPoolWebClientId: '75tj98von1h20nb2j0aframn92' // MFA on
    // userPoolWebClientId: '7i72pfqcvae5367594qujspgb1' // MFA optional
    userPoolWebClientId: process.env.GATSBY_USERS_CLIENT,
    cookieStorage: {
      domain:
        window.location.hostname === "localhost" ? "localhost" : "eprospera.hn",
      secure: window.location.hostname !== "localhost",
      path: "/",
      expires: 1,
      sameSite: "lax",
    },
  },
});
