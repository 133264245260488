module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5LHNPNG","defaultDataLayer":{"type":"object","value":{"platform":"public"}},"routeChangeEventName":"public-route-change","includeInDevelopment":false,"enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icons":[{"src":"./android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"./android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"theme_color":"#ffffff","background_color":"#ffffff","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/codebuild/output/src1409820596/src","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","es"],"defaultLanguage":"en","siteUrl":"https://eprospera.hn/","i18nextOptions":{"interpolation":{"escapeValue":false},"defaultNS":"button","fallbackNS":["login","placeholder","dashboard","application","terms","payment","personal","contact","review","identification","mfa","user_dashboard","residency","settings","modal","application_complete","common","landing","community","privacy-policy","marketplace","terms-of-service","insured-nomads","payoneer","prospera-employment-solutions","prospera-insurance-company","amity-age-academy","candypay","coinable","open-node","reap-technologies","request-finance","sphere","pristine-heights"],"keySeparator":".","nsSeparator":":","ns":["login","button","placeholder","dashboard","application","terms","payment","personal","contact","review","identification","mfa","user_dashboard","residency","settings","modal","application_complete","common","landing","community","privacy-policy","marketplace","terms-of-service","insured-nomads","payoneer","prospera-employment-solutions","prospera-insurance-company","amity-age-academy","candypay","coinable","open-node","reap-technologies","request-finance","sphere","pristine-heights"],"pages":[{"matchPath":"/:lang?/entities/:id","getLanguageFromPath":true},{"matchPath":"/:lang?/resident/:publicId","getLanguageFromPath":true},{"matchPath":"/:lang?/:route","getLanguageFromPath":true}]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
